<template>
  <DefaultError>
    <h1 class="text-grey color-60 pd-t-5">
      ขออภัย ไม่พบหน้าดังกล่าว<br />
      <span
        class="text-white not-hover cursor-pointer font-20 text-primary"
        @click="signOut"
        ><u>กลับสู่หน้าหลัก</u></span
      >
    </h1>
  </DefaultError>
</template>
<script>
import DefaultError from "@/template/DefaultError";
import { mapState } from "vuex";

export default {
  components: {
    DefaultError,
  },
  data() {
    return {};
  },
  computed: {
    ...mapState({
      _token: (state) => state._token,
      user: (state) => state.user,
      pageActive: (state) => state.pageActive,
    }),
  },
  methods: {
    signOut() {
      console.log("signOut started");
      if (
        typeof this.pageActive === "undefined" ||
        this.pageActive === null ||
        this.pageActive === ""
      ) {
        this.$router.push(`/login`);
      } else {
        this.$router.push(`/${this.pageActive}`);
      }
    },
  },
};
</script>

<style scoped>
*,
*:before,
*:after {
  box-sizing: unset;
}
</style>
